<template>
  <button class="" @click="onClicked">
    <!-- <span >{{ before }}</span> -->
    <span >{{ presDisplay }}</span>
    <!-- <span >{{ after }}</span> -->
  </button>
</template>

<script>
export default {
  name: 'AttendBox',
  data() {return {
  }},
  props: {
    presence: {
      default: 0,
      type: Number,
    },
    student: Object,
    position: Number,
    before: String,
    after: String
  },
  computed: {
    presDisplay() {
      if(this.presence < 0 ) {
        return 'A'     // 'absent'
      } else if(this.presence > 0) {
        return 'P'      // 'present' 
      } else {
        return ''       // 'unknown'
      }
    }
  },
  methods: {
    onClicked() {
      //this.presence = rotPresence(this.presence)
      //console.log(this.presence)
      this.$emit('changed', this)  //this.position, this.presence, this.student)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span {
  font-size: large;
}
a {
  color: #00ff00;
}
button {
/*  padding: 20px 20px; */
  height:40px;
  width:40px;
  display: inline-block;
  vertical-align: top;
  border: none;
  background-color: transparent;
}
/* button css to control button size with no text */

</style>
