<template>
  <p style="margin-bottom:1cm;"></p>
  <input 
     class="is-size-4"
     v-model="tagFilter" 
     placeholder="Filter tags" 
     :title="filteredTags.length.toString() + ' tags showing'"
     @keyup.enter="tagFilterEnter()"/>
  <p style="margin-bottom:20px;"></p>

  <div class="inbox">
    <div class="tags">
      <div v-for="t in filteredTags" :key="t.id">
        <span class="tag is-info mr-2 tagcursor" @click="clickedTag(t)">{{ t.title }}</span>
      </div>
    </div>
  </div>
  <p style="margin-bottom:1cm;"></p>
  <div class="scrollingtable">
    <div>
      <div class="tableparent">
      <div class="table-container">
        <table class="table is-bordered is-fullwidth" >
          <caption class="is-size-4">{{lessonsCaption}}</caption>
          <thead>
              <tr>
                <th class="firstcol">Book-Lesson</th>
                <th>Lesson Title</th>
              </tr>
          </thead>
          <tbody>
            <tr v-for="l in filteredLinks" :key="l.id">
              <td class="firstcol">{{l.book}}-{{l.lesson}}</td>
              <td class="has-text-left">
                <input v-if="isLinked(l)" type="checkbox" class="rightpadding"/>
                <a v-if="isLinked(l)" :href="l.link" target="_blank">{{l.title}}</a>
                <span v-else>{{l.title}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
    </div>
  </div>
  <p style="margin-bottom:1cm;"></p>

</template>

<script>
import gql from 'graphql-tag'

const QUERY_TAGS = gql`
query queryTags { learnlinktags { id title detail } }
`
const QUERY_LINKS = gql`
query queryLinks($cwId:Int!) { learnlinks(cwId:$cwId) { id book lesson unit template skill description title link tags { id title detail } } }
`

export default {
  name: 'App',
  inject: ['meta'],
  created() {
    this.meta.setTitle('TW Learning Links');
  },
  components: {
  },
  setup () {
  },
  data() { return {
      tags: [],
      tagFilter: '',
      learnLinks: [],
      learnLinksFilter: '',
    }
  },
  computed: {
    lessonsCaption() {
      let cap = 'Lessons'
      if(this.filteredLinks) {
        cap += ' (' + this.filteredLinks.length + ')'
      }
      if(this.learnLinksFilter) {
        cap += ' for skill: ' + this.learnLinksFilter
      }
      return cap
    },
    filteredTags() {
      if(this.tagFilter) {
        return this.tags.filter(x => x.title.toLowerCase().includes(this.tagFilter.toLowerCase()))
      } else {
        return this.tags
      }
    },
    filteredLinks() {
      if(this.learnLinksFilter) {
        return this.learnLinks.filter(x => x.skill.toLowerCase().includes(this.learnLinksFilter.toLowerCase()))
      } else {
        return this.learnLinks
      }
    },
    colHeaders() { 
      let h = ['Book-Lesson-Unit', 'Lesson Title']
      return h
    },
  },
  methods: {
    isLinked(l) { // TODO: the loading code should be changed so missing links are null instead of '0'
      return l.link!='0'
    },
    tagFilterEnter() {
      // this is the way to clear the filter - enter on a blank tag filter
      if(! this.tagsFilter) {
        this.learnLinksFilter = ''
      }
    },
    clickedTag(tag) {
      console.log(tag)
      this.learnLinksFilter = tag.title
    },
    //onAttendChanged(position, presence, student) {
      //this.$apollo.mutate({mutation:MUTATE_CREATEATTEND, 
      //                    variables:{sid: student.id, classId: 23809, when: dt.toISOString() , svId: this.getSvId(), presence: presence}
      //}).then((response) => {
      //  console.log(response.data)
      //}).catch((response) => {
      //  console.log(response)
      //})
    //},
  }, // methods
  mounted() {
    this.$apollo.query({query:QUERY_TAGS}).then((response) => {
      console.log(response.data)
      this.tags = response.data.learnlinktags

      this.$apollo.query({query:QUERY_LINKS, variables:{cwId:1}}).then((response) => {
        console.log(response.data)
        this.learnLinks = response.data.learnlinks
      }).catch((response) => {
        console.log(response)
      })


      // copy the object which is immutable and add some fields
      //this.classmembers = response.data.etcoclassmember.map(item => Object.assign({}, item, { lastact: null, noteStatsOpen: 0, noteStatsClosed: 0 }))
    }).catch((response) => {
      console.log(response)
    })
  },
}
</script>

<style>
.rightpadding {
 margin-right:4px;
}
.inbox {
  width: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
table,
td {
    border: 1px solid #333;
}
thead,
tfoot {
    background-color: #333;
    color: #fff;
}
.firstcol {
    text-align: left;
    width: 200px;
}
/* ---- slider ---- */
.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}
.border {
  border: 2px blue dashed;
}

.ml-0 {
  margin-left: 0;
}
.mr-auto {
  margin-right:auto;
}
.d-block {
  display:block;
}


/*.scrollingtable */

/* this kinda works but header doesnt match the body
thead, tbody { display: block; }
tbody {
    height: 600px;        Just for the demo          
    overflow-y: auto;     Trigger vertical scroll   
    overflow-x: hidden;   Hide the horizontal scroll 
} */

/* simplest solution for scrolling table body with fixed header - but tweaking needed */
th {
  position: sticky;
  top: 0;
  z-index: 2;
}
.tableparent {
  overflow-y: auto; max-height: 600px;
}

.tagcursor {
  cursor: pointer;
}



html {
	width: 100%; /*required if using % width*/
	/*height: 100%;*/ /*required if using % height*/
}
body {
	/*width: 100%;*/ /*required if using % width*/
	/*height: 100%;*/ /*required if using % height*/
	/*margin: 0;*/ /*required if using % width or height*/
	/*padding: 0 20px 0 20px;*/ /*purely aesthetic, not required*/
  /*box-sizing: border-box;*/ /*required if using above declaration*/
  background: white;
}








</style>
