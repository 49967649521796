<template>
  <div>
    <p style="margin-bottom:1cm;"></p>
    <h4 class='is-size-4'>{{login ? 'Welcome to TUTORWORKS' : 'Sign Up'}}</h4>
    <p style="margin-bottom:1cm;"></p>
    <div class='' style="width:400px; margin:0 auto;">
      <input class='input is-size-4'
        v-show="!login"
        v-model="name"
        type="text"
        placeholder="Name">
      <input class='input is-normal'
        v-model="userName"
        type="text"
        placeholder="Username">
      <p style="margin-bottom:12px;"></p>
      <input class='input is-normal'
        v-model="password"
        type="password"
        placeholder="Password">
    </div>
    <p style="margin-bottom:1cm;"></p>
    <div class=''>
      <button v-if="! succeeded"
        type="button"
        class='button'
        @click="confirm()">
        {{login ? 'Login' : 'create account'}}
      </button>
      <button v-if="false"
        type="button"
        class='button'
        @click="login = !login">
        {{login ? 'need to create an account?' : 'already have an account?'}}
      </button>
      <p v-if="succeeded">Welcome {{userName}}!</p>
    </div>
  </div>
</template>

<script>
  import { TW_USER_ID, TW_USER_NAME, TW_AUTH_TOKEN, TW_CLASS_ID } from '../constants/settings'
  import gql from 'graphql-tag'

  const MUTATION_SIGNIN = gql`
  mutation SignIn($username: String!, $pw: String!) { tokenAuth(username: $username, password:$pw) { refreshExpiresIn payload token} }
  `

  export default {
    name: 'AppLogin',
    data () {
      return {
        userName: '',
        login: true,    // we are not supporting creating users - that is done through etco
        name: '',
        password: '',
        succeeded: false,
      }
    },
    methods: {
      confirm () {
        const { userName, password } = this.$data
        this.$apollo.mutate({mutation:MUTATION_SIGNIN, 
                                  variables:{ username: userName, pw: password}
        }).then((response) => {
          //console.log(response.data)
          let parts = response.data.tokenAuth.payload.username.split(',')
          const username = parts[0]
          const id = parts[1]
          const token = response.data.tokenAuth.token
          this.saveUserData(id, username, token)
          this.succeeded = true
          window.location.hash = '#/attend'
        }).catch((response) => {
          console.log(response)
          this.saveUserData('', '', '')
          alert(response)
        })

      },
      saveUserData (id, name, token) {
        localStorage.setItem(TW_USER_ID, id)
        localStorage.setItem(TW_USER_NAME, name)
        localStorage.setItem(TW_AUTH_TOKEN, token)
        localStorage.setItem(TW_CLASS_ID, '')   // we may decide this has a useful life beyond user persist, but for now keep it simple
        this.$root.$data.userId = localStorage.getItem(TW_USER_ID)
        this.$root.$data.userName = localStorage.getItem(TW_USER_NAME)
      }
    },
    mounted() {
      this.saveUserData('', '', '')
    }
  }
</script>
