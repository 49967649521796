export var isSeen = (s) => {
    return s.seenCount || s.attendsConfirmed[0]
}


export var membCompare = (a,b) => {
    //         if they are diff       one decides       otherwise move on to strings
    return (
            ( // although negated logic seems awkward - resolve high priority first then the fall through to lower
              !(!!a.noteStatsOpen == !!b.noteStatsOpen) // one or more open, !! converts to boolean - dont care about magnitude
                ?(a.noteStatsOpen ? -1:1)               // open notes first
                : !(isSeen(a) == isSeen(b))
                  ?(isSeen(a) ? -1:1)                     // seen first
                  //:(a.lastact != b.lastact)                                         // this caused user confusion
                  //  ?(a.lastact ? -1:1)
                  :!(a.grade == b.grade)
                    ?(a.grade - b.grade)                    // ascending grades
                    :(a.fn+a.ln).localeCompare(b.fn+b.ln)     // ascending names
            )
    )
}


const cohort = 'cohort'
export var extractClassName = (s) => {
  // only text ending with cohort is valid for display - but exclude the suffix
  let cl = ''
  if(s.toLowerCase().endsWith(cohort)) {
    cl = s.slice(0, s.length-cohort.length).trimEnd()
  }
  return cl
}
